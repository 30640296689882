import React from "react";
import { Container, Wrapper, Row, Box } from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Image from "../components/image";

const Services = (props) => {
  const GeoServices = [
    "Engineering assessments of steep sites",
    "Slope stability and risk assessments",
    "Slope stability works design and documentation",
    "Retaining wall design and documentation",
    "Foundation investigation and assessments",
    "Foundation design and documentation",
    "Subdivision engineering and feasibility assessments",
    "Earthworks design, supervision and certification",
    "Engineering geological assessments",
    "Earthquake fault trace location",
    "Residential site condition assessments",
    "Existing retaining/structure condition advice and assessments",
    "Specialist geotechnical/foundation advice",
    "Telecommunications Engineering and Design",
    "Embankment design",
    "Stop bank design",
    "Design of deep excavations",
    "Soil-structure interaction",
    "Finite element two-dimensional and three-dimensional analysis",
    "Underground structures with three-dimensional numerical analysis",
    "Liquefaction analysis",
    "Foundation and pile design for buildings and bridge structures",
    "Analysis of piles and basement walls in sloping ground under static and seismic loads",
    "2D and 3D numerical analysis",
    "Soil structure interaction analysis",
    "Design of deep excavations",
    "Design of catchfences",
    "Seepage analysis",
  ];

  const CivilServices = [
    "Pavement design",
    "Driveway design and documentation",
    "Car decks design and documentation",
    "General civil works design, supervision and certification",
    "Professional advice on fill certification support and underpinning of structures",
    "Stormwater disposal design",
    "Sewage disposal design/percolation testing",
  ];

  const DamsAndStopBanks = [
    "Design of dams and stopbanks",
    "Certifying Potential Impact Classification (PIC) and Dam Safety Assurance Programs (DSAP)",
    "Classifying Potential Impact Classification (PIC) and preparing Dam Safety Assurance Programs (DSAP)",
  ];

  return (
    <Layout>
      <Seo title="Services" pathname={props.location.pathname} />
      <Container>
        <Wrapper>
          <h1 className="-textCenter">General Services</h1>
          <Row gap={80} alignCenter>
            <Box gap={20} size={50}>
              <h2>Geotechnical (Soil) Investigations</h2>
              <ul>
                {GeoServices.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </Box>
            <Box size={50}>
              <Image filename="GeoTech_Image.jpg" alt="" rounded addShadow />
            </Box>
          </Row>
          <Row gap={80} alignCenter>
            <Box size={50}>
              <Image
                filename="CivilServices_Image.jpg"
                alt="Civil Engineer Services | Abuild"
                rounded
                addShadow
              />
            </Box>
            <Box gap={20} size={50}>
              <h2>Civil Engineering</h2>
              <ul>
                {CivilServices.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </Box>
          </Row>
          <Row gap={80} alignCenter>
            <Box gap={20} size={50}>
              <h2>Dams & Stopbanks</h2>
              <ul>
                {DamsAndStopBanks.map((item, i) => {
                  return <li key={i}>{item}</li>;
                })}
              </ul>
            </Box>
            <Box size={50}>
              <Image
                filename="DamStopbank_Image.jpg"
                alt=""
                rounded
                addShadow
              />
            </Box>
          </Row>
        </Wrapper>
      </Container>
    </Layout>
  );
};

export default Services;
